import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";

// import page style
import './404.scss';

const NotFoundPage = () => (
  <Layout className="patient-404"
  noShowStickyIsi>
  <Seo pageTitle="Page Not Found" pageDescription="Page Not Found" />
    <div className="centered-content">
			<Row>
        <Col xs={12}>
          <h1>NOT FOUND</h1>
          <p>Sorry, this page doesn't exist!</p>
        </Col>
      </Row>
    </div>
  </Layout>
);

export default NotFoundPage;
